<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            v-if="invite"
          >
            <b>Welcome to IDLEAF</b>
            <div>Please choose your password</div>
          </div>
          <div
            v-else
          >
            <b>Reset password</b>
            <div>Please set your new password</div>
          </div>

          <!-- form -->
          <validation-observer
            ref="resetForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="reset"
            >

              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="New Password"
                  vid="newPasswordValue"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      :state="errors.length > 0 ? false:null"
                      name="new-password"
                      placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Retype New Password"
                  vid="RetypePassword"
                  rules="required|confirmed:newPasswordValue"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Set Password
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { confirmed, required } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      sideImg: require('@/assets/images/logo/logo-idleaf.svg'),
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      // validation rules
      confirmed,
      required,
      invite: this.$route.query.invite === '1',
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-idleaf.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    reset() {
      this.$refs.resetForm.validate().then(success => {
        if (success) {
          const { uid, token } = this.$route.params
          this.$http.post('/api/v1/users/reset_password_confirm/', {
            uid,
            token,
            new_password: this.newPasswordValue,
            re_new_password: this.RetypePassword,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Password modified',
                  icon: 'KeyIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              if (error.response) {
                let {
                  new_password: newPasswordValueError,
                  re_new_password: RetypePasswordError,
                  token: tokenError,
                } = error.response.data
                if (newPasswordValueError) {
                  [newPasswordValueError] = newPasswordValueError
                }
                if (RetypePasswordError) {
                  [RetypePasswordError] = RetypePasswordError
                }
                if (tokenError) {
                  [tokenError] = tokenError
                }
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: newPasswordValueError || RetypePasswordError || tokenError,
                    icon: 'KeyIcon',
                    variant: 'danger',
                  },
                })
              } else if (error.request) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: error.request,
                    icon: 'KeyIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
